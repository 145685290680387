import React, { useState } from "react";
import axios from "axios";
import "./DeleteImageButton.css";

const apiUrl = process.env.REACT_APP_API_URL;

const DeleteImageButton = ({ imageId, onDeleteSuccess }) => {
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${apiUrl}/models/image/${imageId}`, {
        withCredentials: true,
      });
      console.log(response.data.message);
      setMessage("Image deleted successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds

      if (onDeleteSuccess) {
        onDeleteSuccess(imageId);
      }
    } catch (error) {
      setMessage("Error deleting image");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      console.error(
        "Error deleting image:",
        error.response?.data?.message || error.message
      );
    }
  };

  return (
    <>
      {showPopup && <div className="popup">{message}</div>}
      <button onClick={handleDelete}>Delete Image</button>
    </>
  );
};

export default DeleteImageButton;
