import React from 'react';
import './AccountDeletionPolicy.css';

const AccountDeletionPolicy = () => {
    return (
        <div className="account-deletion-policy">
            <h1>Account Data and Deletion Policy</h1>
            
            <section>
                <h2>Account Deactivation</h2>
                <p>At any time, users have the option to deactivate their account. During deactivation, your profile and content will become invisible to other users, but your data remains intact on our systems. You can reactivate your account at any time by logging back in.</p>
            </section>

            <section>
                <h2>Account Deletion Request</h2>
                <p>Users may also request to permanently delete their account. Upon submission of a deletion request, your account will be marked for deletion. However, in case you change your mind, you have a 90-day grace period to log back in and cancel the deletion process. If you do not log in within these 90 days, your account and all associated content will be permanently deleted from our platform.</p>
            </section>

            <section>
                <h2>Respecting Your Privacy</h2>
                <p>We respect your right to privacy and ensure that your personal data is handled with care throughout this process.</p>
            </section>
        </div>
    );
};

export default AccountDeletionPolicy;
