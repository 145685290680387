// client/src/components/AddModel.js

import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AddModel = () => {
  const [modelData, setModelData] = useState({
    name: "",
    photo: null,
    coverPhoto: null,
    age: "",
    city: "",
    country: "",
    bio: "",
    birthYear: "",
    hairColor: "",
    eyeColor: "",
    height: "",
    weight: "",
    breasts: "",
    realOrFake: "",
    hips: "",
    waist: "",
    facebook: "",
    instagram: "",
    otherSocialMedia: "",
  });
  const [existingNames, setExistingNames] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Function to fetch existing model names to prevent duplicates
    const fetchModelNames = async () => {
      try {
        const response = await axios.get(`${apiUrl}/models/names`);
        setExistingNames(response.data.names);
      } catch (error) {
        console.error("Error fetching model names:", error);
        setError("Failed to fetch existing model names.");
      }
    };

    fetchModelNames();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModelData({ ...modelData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setModelData({ ...modelData, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the model name already exists
    if (existingNames.includes(modelData.name.trim())) {
      setError("Model name already exists.");
      return;
    }

    setIsSubmitting(true);
    setError("");

    const formData = new FormData();
    Object.keys(modelData).forEach((key) => {
      formData.append(key, modelData[key]);
    });

    try {
      const response = await axios.post(`${apiUrl}/models`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Model added successfully!");
      // Reset form if needed or navigate away
    } catch (error) {
      console.error("Error adding model:", error);
      setError("Failed to add model.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Model Name:</label>
        <input
          type="text"
          name="name"
          required
          value={modelData.name}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Photo:</label>
        <input type="file" name="photo" onChange={handleFileChange} />
      </div>
      <div>
        <label>Cover Photo:</label>
        <input type="file" name="coverPhoto" onChange={handleFileChange} />
      </div>
      {/* Repeat for other fields */}
      <div>
        <label>Age:</label>
        <input
          type="number"
          name="age"
          value={modelData.age}
          onChange={handleInputChange}
          min="18" // Optional: Specify a minimum value
          max="120" // Optional: Specify a maximum value
        />
      </div>
      <div>
        <label>City:</label>
        <input
          type="text"
          name="city"
          value={modelData.city}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Country:</label>
        <input
          type="text"
          name="country"
          value={modelData.country}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Birth Year:</label>
        <input
          type="number"
          name="birthYear"
          value={modelData.birthYear}
          onChange={handleInputChange}
          min="1900" // Adjust the min and max according to your requirements
          max={new Date().getFullYear()}
        />
      </div>

      <div>
        <label>Hair Color:</label>
        <input
          type="text"
          name="hairColor"
          value={modelData.hairColor}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Ethnicity:</label>
        <input
          type="text"
          name="ethnicity"
          value={modelData.ethnicity}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Aliases:</label>
        <input
          type="text"
          name="aliases"
          value={modelData.aliases}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Breast Size:</label>
        <input
          type="text"
          name="breasts"
          value={modelData.breasts}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Real or Fake:</label>
        <select
          name="realOrFake"
          value={modelData.realOrFake}
          onChange={handleInputChange}
        >
          <option value="Real">Real</option>
          <option value="Fake">Fake</option>
        </select>
      </div>

      <div>
        <label>Waist Size (in inches):</label>
        <input
          type="number"
          name="waist"
          value={modelData.waist}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Hips Size (in inches):</label>
        <input
          type="number"
          name="hips"
          value={modelData.hips}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Height (in cm):</label>
        <input
          type="number"
          name="height"
          value={modelData.height}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Weight (in kg):</label>
        <input
          type="number"
          name="weight"
          value={modelData.weight}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Instagram URL:</label>
        <input
          type="url"
          name="instagramUrl"
          value={modelData.instagramUrl}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Facebook URL:</label>
        <input
          type="url"
          name="fbUrl"
          value={modelData.fbUrl}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Steam URL:</label>
        <input
          type="url"
          name="steamUrl"
          value={modelData.steamUrl}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label>Tags:</label>
        <input
          type="text"
          name="tags"
          value={modelData.tags}
          onChange={handleInputChange}
        />
      </div>
      {/* ... Continue for the rest of the fields ... */}
      <button type="submit" disabled={isSubmitting}>
        Add Model
      </button>
      {error && <p className="error">{error}</p>}
    </form>
  );
};

export default AddModel;
