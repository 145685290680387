import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>ML Studios Image Sharing Site. This site is currently under development.</p>
    </footer>
  );
};

export default Footer;