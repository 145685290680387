import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./ModelProfileComponent.css";
import PhotoUploadForm from "./PhotoUploadForm";
import UpdateModel from "./UpdateModel";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const ModelProfileComponent = () => {
  const [model, setModel] = useState({ images: [] }); // Initialize with an images array
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchModel = async () => {
      if (slug) {
        try {
          const response = await axios.get(`${apiUrl}/models/${slug}`);
          response.data.Images.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setModel(response.data);
        } catch (error) {
          console.error("Error fetching model details", error);
        }
      }
    };

    fetchModel();
  }, [slug]);

  const displayField = (field, defaultValue = "N/A") => {
    return model && model[field] ? model[field] : defaultValue;
  };

  const onImageUpload = (newImageData) => {
    setModel((prevModel) => ({
      ...prevModel,
      images: Array.isArray(prevModel.images)
        ? [...prevModel.images, newImageData]
        : [newImageData],
    }));
  };

  const incrementViewCount = async (imageId) => {
    try {
      await axios.post(
        `${apiUrl}/models/${slug}/images/${imageId}/increment-views`
      );
      // You may need to update the image view count in the state as well, depending on how you want to handle this.
    } catch (error) {
      console.error("Error incrementing views", error);
    }
  };

  const handleTagClick = (tagSlug) => {
    navigate(`/models/tags/${tagSlug}`);
  };

  return (
    <div className="model-container">
      <Helmet>
        <title>
          {displayField("name")} Model, Pornstar Bio - Nude Photos - ML Studios
        </title>
        <meta
          name="description"
          content={`Learn more about pornstar ${displayField(
            "name"
          )}, her biography, nude photos, and work with ML Studios.`}
        />
      </Helmet>
      {model ? (
        <>
          <div className="model-header">
            <h1>{displayField("name")}</h1>
            <h1>{displayField("age")}</h1>
            <h1>{displayField("city")}</h1>
            <h1>{displayField("country")}</h1>
            <h1>{displayField("bio")}</h1>
            <h1>{displayField("birthYear")}</h1>
            <h1>{displayField("hairColor")}</h1>
            <h1>{displayField("views")}</h1>
            <h1>{displayField("rating")}</h1>
            <div className="model-tags">
              {model.Tags &&
                model.Tags.map((tag) => (
                  <button
                    key={tag.id}
                    onClick={() => handleTagClick(tag.slug)}
                    className="tag-button"
                  >
                    {tag.name}
                  </button>
                ))}
            </div>
            <h5>
              Have a photo of her? Upload now and share with others to see.
            </h5>
            <PhotoUploadForm
              modelSlug={model.slug}
              onImageUpload={onImageUpload}
            />
            <img
              className="model-image"
              src={displayField("photo")}
              alt={`Profile of ${displayField("name")}`}
            />
            <img
              className="model-image"
              src={displayField("coverPhoto")}
              alt={`Cover of ${displayField("name")}`}
            />
            <UpdateModel />
          </div>
          {/* Other model details */}
          <h5>Click on a photo to view in Full screen mode</h5>
          <div className="profile-gallery">
            {model.Images &&
              model.Images.map((image, index) => {
                // Calculate the correct numbering for the image
                const imageNumber = model.Images.length - index;
                return (
                  <img
                    className="model-image"
                    key={image.id}
                    src={image.url}
                    alt={`${model.name} nude ${imageNumber}`} // Corrected alt text
                    onClick={() => {
                      navigate(
                        `/models/${model.slug}/image/${
                          image.id
                        }/${encodeURIComponent(image.url)}`
                      );
                      incrementViewCount(image.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <p>Loading model details...</p>
      )}
    </div>
  );
};

export default ModelProfileComponent;
