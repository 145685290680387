import React, { useEffect } from 'react';

const OAuthCallback = () => {
  useEffect(() => {
    // Extract the code from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // TODO: Add logic to exchange the code for tokens
    }
  }, []);

  return (
    <div>
      Processing login...
    </div>
  );
};

export default OAuthCallback;
