import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./UserProfile.css";

const apiUrl = process.env.REACT_APP_API_URL;

const UserProfile = () => {
  const { slug } = useParams();
  const [user, setUser] = useState(null);
  console.log(user);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users/${slug}`);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [slug]);

  if (!user) {
    return <div>Loading user data...</div>;
  }
  return (
    <div>
      <h2>{user.username}</h2>
      <img src={user.profilePicture} alt={`${user.username}'s profile`} />
      <p>Email: {user.email}</p>
      <p>Account Type: {user.accountType}</p>
      {/* Display user's uploaded images */}
      <div>
        <h3>Uploaded Images</h3>
        <div className="images-wrapper">
          {user.Images &&
            user.Images.map((image, index) => (
              <div key={index} className="image-container">
                <Link
                  to={`/models/${image.ModelProfile.slug}/image/${
                    image.id
                  }/${encodeURIComponent(image.url)}`}
                >
                  <img
                    src={image.url}
                    alt={`Uploaded by ${user.username}`}
                    className="uploaded-image"
                  />
                </Link>
                <p>Views: {image.views}</p>
                <p>Average Rating: {image.averageRating}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
