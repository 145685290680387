import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../AuthContext"; // Adjust the import path

const apiUrl = process.env.REACT_APP_API_URL; // Ensure you have your API URL set

const EditUserProfile = () => {
  const { user: loggedInUser } = useContext(AuthContext);
  const { slug } = useParams();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    profilePicture: "",
    coverPicture: "",
    gender: "",
    accountType: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users/${slug}`, {
          withCredentials: true,
        });
        const userData = {
          ...response.data,
          profilePicture: response.data.profilePicture || "",
          coverPicture: response.data.coverPicture || "",
          gender: response.data.gender || "",
          accountType: response.data.accountType || "",
          // ... handle other fields similarly ...
        };
        setFormData(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [slug]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`${apiUrl}/users/update/${slug}`, formData, {
        withCredentials: true,
      });
      // Add your success logic here
      alert("Profile updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
      setError("Error updating profile");
    } finally {
      setLoading(false);
    }
  };

  const isAdmin = loggedInUser && loggedInUser.accountType === "admin";

  return (
    <div>
      <h2>Edit Profile</h2>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="profilePicture">Profile Picture URL</label>
          <input
            type="text"
            name="profilePicture"
            value={formData.profilePicture}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="coverPicture">Cover Picture URL</label>
          <input
            type="text"
            name="coverPicture"
            value={formData.coverPicture}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="gender">Gender</label>
          <select name="gender" value={formData.gender} onChange={handleChange}>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        {isAdmin && (
          <div>
            <label htmlFor="accountType">Account Type</label>
            <select
              name="accountType"
              value={formData.accountType}
              onChange={handleChange}
            >
              <option value="admin">Admin</option>
              <option value="moderator">Moderator</option>
              <option value="premium user">Premium User</option>
              <option value="free user">Free User</option>
            </select>
          </div>
        )}

        <button type="submit" disabled={loading}>
          Update Profile
        </button>
      </form>
    </div>
  );
};

export default EditUserProfile;
