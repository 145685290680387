import "./FullContainer.css";

const FullContainer = ({ children }) => {
  return (
    <div className="container">
      {children}
    </div>
  );
};

export default FullContainer;
