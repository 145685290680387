/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./RightSection.css";

const apiUrl = process.env.REACT_APP_API_URL;

const RightSection = () => {
  const [topPictures, setTopPictures] = useState([]);
  const [latestPictures, setLatestPictures] = useState([]);

  useEffect(() => {
    const fetchTopPictures = async () => {
      try {
        const response = await axios.get(`${apiUrl}/top-images`);
        setTopPictures(response.data);
      } catch (error) {
        console.error("Error fetching top pictures:", error);
        // Handle error appropriately
      }
    };

    const fetchLatestPictures = async () => {
      try {
        const response = await axios.get(`${apiUrl}/latest-pictures`);
        setLatestPictures(response.data);
      } catch (error) {
        console.error("Error fetching latest pictures:", error);
        // Handle error appropriately
      }
    };

    fetchTopPictures();
    fetchLatestPictures();
  }, []);

  return (
    <div className="right-section">
      <h2>Top Pictures</h2>
      <div className="top-pictures">
        {topPictures.map((picture) => (
          <Link
            to={`/models/${picture.slug}/image/${
              picture.id
            }/${encodeURIComponent(picture.url)}`}
          >
            <img
              key={picture.id}
              src={picture.url}
              alt={`${picture.ModelProfile.name} nude`}
            />
          </Link>
        ))}
      </div>

      <h2>Latest Pictures</h2>
      <div className="latest-pictures">
        {latestPictures.map((picture) => (
          <Link
            to={`/models/${picture.slug}/image/${
              picture.id
            }/${encodeURIComponent(picture.url)}`}
          >
            <img
              key={picture.id}
              src={picture.url}
              alt={`${picture.ModelProfile.name} nude`}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RightSection;
