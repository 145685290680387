import React, { useContext } from "react";
import { AuthContext } from './AuthContext';

const LogoutButton = () => {   

    const { handleLogout: onLogout } = useContext(AuthContext);

    
    return <button onClick={onLogout}>Logout</button>;
};

export default LogoutButton;
