import React, { useContext } from "react";
import { AuthContext } from './AuthContext';
import { NavLink } from 'react-router-dom';
import './Nav.css'; // Assuming your CSS file is in the same directory
import LogoutButton from './LogoutButton';

const Navbar = () => {

  const { isLoggedIn, handleLogout } = useContext(AuthContext);

  return (
    <nav className="navbar">
      <div className="nav-container">
        <NavLink to="/" className="nav-logo">Studio Hub</NavLink>
        <ul className="nav-links">
          <li><NavLink to="/" className={({ isActive }) => isActive ? "active" : undefined}>Home</NavLink></li>
          <li><NavLink to="/models" className={({ isActive }) => isActive ? "active" : undefined}>Models</NavLink></li>
          <li><NavLink to="/gallery" className={({ isActive }) => isActive ? "active" : undefined}>Gallery</NavLink></li>
          <li><NavLink to="/contact" className={({ isActive }) => isActive ? "active" : undefined}>Contact</NavLink></li>
          <li>UNDER DEVELOPMENT - Might not work on mobile</li>
          {isLoggedIn && <LogoutButton onLogout={handleLogout} />}
          {!isLoggedIn && <button><a href="/login">Login</a></button> }
          {isLoggedIn && <h5>LOGGED IN</h5>}
        </ul>
      </div>
    </nav>
  );
};


export default Navbar;
