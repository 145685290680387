
const apiUrl = process.env.REACT_APP_API_URL;

const FacebookLoginButton = () => {
    const handleLogin = () => {
      window.location.href = `${apiUrl}/auth/facebook`;
    };
  
    return (
      <button onClick={handleLogin}>
        Login with Facebook
      </button>
    );
  };
  
  export default FacebookLoginButton;
  