import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext"; // Adjust the import path

const ProtectedRoute = ({ children, allowedAccountTypes }) => {
  const { user, loading } = useContext(AuthContext);

  // Check if still loading
  if (loading) {
    return <div>Loading...</div>; // or any loading indicator
  }


  // Check if user is not authenticated
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Check if user's account type is not allowed
  if (allowedAccountTypes && !allowedAccountTypes.includes(user.accountType)) {
    return <Navigate to="/access-denied" />;
  }


  return children; // Render children if the user is authenticated and authorized
};

export default ProtectedRoute;
