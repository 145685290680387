import React from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

const GoogleLoginButton = () => {
  const handleLogin = () => {
    // Redirect to your Passport Google auth route
    window.location.href = `${apiUrl}/auth/google`;
  };

  return (
    <button onClick={handleLogin}>
      Login with Google
    </button>
  );
};

export default GoogleLoginButton;
