import React, { useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const UpdateModel = () => {
  const [formData, setFormData] = useState({
    name: '',
    photo: '',
    coverPhoto: '',
    // ... other fields
  });
  const [slug, setSlug] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${apiUrl}/models/update/${slug}`, formData);
      console.log(response.data);
      // Handle success (maybe clear form or show a success message)
    } catch (error) {
      console.error('Error updating model', error);
      // Handle error (show error message)
    }
  };

  return (
    <div>
      <h2>Update Model</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="slug" value={slug} onChange={(e) => setSlug(e.target.value)} placeholder="Model Slug" />
        <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" />
        <input type="text" name="photo" value={formData.photo} onChange={handleChange} placeholder="Photo URL" />
        {/* Add other fields here */}
        <button type="submit">Update Model</button>
      </form>
    </div>
  );
};

export default UpdateModel;
