import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import axios from "axios";
import { Rating } from "react-simple-star-rating";
import "./FullScreen.css";

import DeleteImageButton from "./buttons/DeleteImageButton";

const apiUrl = process.env.REACT_APP_API_URL;

const FullScreenImageComponent = () => {
  const { slug, imageId, imageUrl } = useParams();
  const navigate = useNavigate();
  const decodedImageUrl = decodeURIComponent(imageUrl);
  const [viewCount, setViewCount] = useState(0);
  const [rating, setRating] = useState(0); // State for storing user's rating
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const handleDeleteSuccess = (deletedImageId) => {
    setDeleteMessage(`Image with ID ${deletedImageId} was deleted.`);
    setShowDeleteMessage(true);
    setTimeout(() => {
      setShowDeleteMessage(false);
      navigate("/"); // Navigate to home or another appropriate page
    }, 3000); // Adjust time as needed
  };

  const formatTitle = (slug) => {
    return slug
      .replace(/[-_]+/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const modelName = formatTitle(slug);

  useEffect(() => {
    // Create an axios cancel token
    const source = axios.CancelToken.source();

    const incrementViewCount = async () => {
      try {
        await axios.post(
          `${apiUrl}/models/${slug}/images/${imageId}/increment-views`,
          { cancelToken: source.token } // Attach the cancel token to the request
        );
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled:", error.message);
        } else {
          console.error("Error incrementing views", error);
        }
      }
    };

    incrementViewCount();

    // When the component unmounts or dependencies change, cancel the axios request
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [slug, imageId]); // Dependencies

  useEffect(() => {
    // Fetch the updated view count whenever imageId changes
    const fetchViewCount = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/models/${slug}/images/${imageId}/view-count`
        );
        setViewCount(response.data.views);
      } catch (error) {
        console.error("Error fetching view count", error);
      }
    };

    fetchViewCount();
    // This effect should run every time imageId changes
  }, [slug, imageId]);

  const goBack = () => navigate(-1);

  const handleRating = (newRating) => {
    setRating(newRating);

    axios
      .post(`${apiUrl}/images/${imageId}/rate`, { rating: newRating })
      .then((response) => {
        // Handle the response
      })
      .catch((error) => {
        console.error("Error submitting rating", error);
      });
  };

  return (
    <div
      onClick={goBack}
      style={{ cursor: "pointer", width: "100vw", height: "100%" }}
      className="full-screen"
    >
      <Helmet>
        <title>{modelName} Nude Photo - ML Studios</title>
      </Helmet>
      <h2>{modelName}'s Photo</h2>
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            <TransformComponent>
              <img
                src={decodedImageUrl}
                alt={modelName + " nude"}
                style={{ width: "100%", height: "auto" }}
                className="full-screen-image"
              />
            </TransformComponent>
            <div className="tools">
              <button onClick={zoomIn}>+</button>
              <button onClick={zoomOut}>-</button>
              <button onClick={resetTransform}>&times;</button>
            </div>
            <div className="download-section">
              <a href={decodedImageUrl} download className="download-button">
                Download Image
              </a>
              <p>Click the button to download the image</p>
            </div>
            {/* Displaying the view count here */}
            <p>Views: {viewCount}</p>
            {showDeleteMessage && <div className="popup">{deleteMessage}</div>}
            <DeleteImageButton
              imageId={imageId}
              onDeleteSuccess={handleDeleteSuccess}
            />
            {/* Star Rating Component */}
            <Rating
              onClick={handleRating}
              ratingValue={rating}
              size={20}
              iconsCount={10} // Set the number of stars to 10
              fillColor="orange"
              emptyColor="gray"
              allowHalfIcon={false} // Disable half stars
            />
          </>
        )}
      </TransformWrapper>
    </div>
  );
};

export default FullScreenImageComponent;
