import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const GoogleLoginHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      // Send this code to your backend
      axios.post(`${apiUrl}/users/google-login`, { code })
        .then(response => {
          // Handle login success
          navigate('/models'); // Redirect after successful login
        })
        .catch(error => {
          // Handle login failure
          navigate('/login'); // Redirect back to login on failure
        });
    } else {
      // If no code is present in the URL, redirect to the login page
      navigate('/login');
    }
  }, [searchParams, navigate]);

  return <div>Loading...</div>;
};

export default GoogleLoginHandler;
