import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import './UsersList.css'; // Import the CSS file

const apiUrl = process.env.REACT_APP_API_URL;

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/users/all`, {
          withCredentials: true,
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Error fetching users");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div>Loading users...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>User List</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id} className="user-list-item">
            {user.profilePicture && (
              <img 
                src={user.profilePicture} 
                alt={`${user.username}'s profile`} 
                className="user-profile-picture"
              />
            )}
            <div className="user-details">
              <Link to={`/users/${user.slug}`} className="user-link">{user.username}</Link>
              <span>{user.email} ({user.accountType})</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsersList;
