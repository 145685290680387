import React, { useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const PhotoUploadForm = ({ modelSlug, onImageUpload }) => {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (file, index, totalFiles) => {
    const formData = new FormData();
    formData.append("images", file);

    try {
      await axios.post(
        `${apiUrl}/models/${modelSlug}/images`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUploadProgress(((index + 1) / totalFiles) * 100); // Update progress
    } catch (error) {
      console.error("Error uploading file:", error);
      // Optionally handle individual file upload error
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUploading(true);

    for (let i = 0; i < files.length; i++) {
      await uploadFile(files[i], i, files.length);
    }

    setIsUploading(false);
    if (onImageUpload) {
      onImageUpload();
    }
  };

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
    setUploadProgress(0); // Reset progress when new files are selected
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileChange} multiple />
      <button type="submit" disabled={isUploading}>Upload Images</button>
      {isUploading && (
        <div>
          <p>Uploading images: {Math.round(uploadProgress)}% ({Math.floor(uploadProgress / 100 * files.length)}/{files.length})</p>
          <div style={{ width: "100%", backgroundColor: "#ddd" }}>
            <div style={{ height: "20px", width: `${uploadProgress}%`, backgroundColor: "green" }}></div>
          </div>
        </div>
      )}
    </form>
  );
};

export default PhotoUploadForm;
