import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const ModelsByTagComponent = () => {
    const [models, setModels] = useState([]);
    const { tagSlug } = useParams();

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const response = await axios.get(`${apiUrl}/models/tags/${tagSlug}`);
                setModels(response.data.models);
            } catch (error) {
                console.error("Error fetching models for tag", error);
            }
        };

        if (tagSlug) {
            fetchModels();
        }
    }, [tagSlug]);

    return (
        <div>
            <h1>Models tagged with "{tagSlug}"</h1>
            <div>
                {models.length > 0 ? (
                    models.map(model => (
                        <div key={model.id}>
                            <h2>{model.name}</h2>
                            <img src={model.photo} alt={model.name} />
                            <p>Age: {model.age}</p>
                            <p>City: {model.city}</p>
                            <p>Country: {model.country}</p>
                            {/* Add more model details you want to display */}
                        </div>
                    ))
                ) : (
                    <p>No models found for this tag.</p>
                )}
            </div>
        </div>
    );
};

export default ModelsByTagComponent;
