import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from "react-helmet";
import './GalleryComponent.css'

const apiUrl = process.env.REACT_APP_API_URL;

const GalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('top'); // Default sorting criteria

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/sorted-images`, { // Update this line
          params: { sort: sortCriteria }
        });
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching photos:", error);
      }
    };

    fetchImages();
  }, [sortCriteria]);

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  return (
    <div className="gallery-section">
            <Helmet>
        <title>Model | Pornstar Gallery - ML Studios, Studio Hub</title>
      </Helmet>
      <div className="sort-options">
        <button onClick={() => handleSortChange('top')}>Top</button>
        <button onClick={() => handleSortChange('latest')}>Latest</button>
        <button onClick={() => handleSortChange('views')}>Most Viewed</button>
        <button onClick={() => handleSortChange('rating_high')}>Highest Rating</button>
        <button onClick={() => handleSortChange('rating_low')}>Lowest Rating</button>
      </div>

      <div className="gallery-photos">
        {images.map((photo) => (
          <Link
            key={photo.id}
            to={`/models/${photo.ModelProfile?.slug}/image/${photo.id}/${encodeURIComponent(photo.url)}`}
          >
            <img src={photo.url} alt={`${photo.slug} nude - ML Studios`} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GalleryComponent;
