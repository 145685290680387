import LeftSection from "./LeftSection"; // Adjust the path as necessary
import RightSection from "./RightSection"; // Adjust the path as necessary
import { Helmet } from "react-helmet";
import "./FullContainer.css";

const Home = () => {
  return (
    <div className="container">
      <Helmet>
        <title>ML Studios | Studio Hub - Home of The Most Beautiful Models and Pornstars</title>
      </Helmet>
      <LeftSection />
      <RightSection />
    </div>
  );
};

export default Home;
