import React, { useState, useEffect } from 'react';
import './LeftSection.css';
import axios from 'axios'; // Don't forget to install axios or use fetch

const apiUrl = process.env.REACT_APP_API_URL;

const LeftSection = () => {
    const [topModels, setTopModels] = useState([]);
  
  useEffect(() => {
    const fetchTopModels = async () => {
      try {
        const response = await axios.get(`${apiUrl}/top-models`); // replace with your actual API endpoint
        setTopModels(response.data);
      } catch (error) {
        console.error('Error fetching top models:', error);
        // Handle error appropriately
      }
    };

    fetchTopModels();
  }, []);


  
    return (
      <div className="left-section">
        {/* ... (Model of the Day code remains the same) */}
  
        <div className="top-models">
          <h2>Top Models</h2>
          <ul>
            {topModels.length ? (
              topModels.map((model, index) => (
                <li key={index}>
                  <img src={model.photo} alt={model.name} />
                  <h4>{model.name}</h4>
                  <p>Age: {model.age}</p>
                  <p>Total Views: {model.totalViews}</p>
                  {/* If you have a description or other fields, you can add them here */}
                </li>
              ))
            ) : (
              <p>Loading top models...</p>
            )}
          </ul>
        </div>
      </div>
    );
  };
  
  export default LeftSection;