import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from './AuthContext';
import axios from "axios";
import AddModel from "./AddModel";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./ModelList.css";

const apiUrl = process.env.REACT_APP_API_URL;

const ModelList = () => {
  const { isLoggedIn } = useContext(AuthContext);

  const [models, setModels] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Fetch models from the server
    const fetchModels = async () => {
      try {
        const response = await axios.get(`${apiUrl}/models`); // Adjust the URL to match your server's endpoint
        setModels(response.data);
      } catch (error) {
        console.error(
          "Error fetching models:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchModels();
  }, []); // The empty array ensures this effect runs only once when the component mounts

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(`${apiUrl}/models/search`, {
          params: { name: query },
        });
        setSearchResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Models | Pornstars List - ML Studios, Studio Hub</title>
      </Helmet>
      <h1>Add a New Model</h1>
      {isLoggedIn && <AddModel />}     
      <h2>Models</h2>
      {/* Search Bar */}
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearch}
        placeholder="Search models by name"
        className="search-bar"
      />
      <div className="search-results">
        {searchResults.map((result) => (
          <Link
            to={`/models/${result.slug}`}
            key={result.id}
            className="search-result"
            style={{ textDecoration: "none" }}
          >
            <div className="result-item">
              <img src={result.photo} alt={`Model ${result.name}`} />
              <span>{result.name}</span>
            </div>
          </Link>
        ))}
      </div>
      <div className="models-container">
        {models.length > 0 ? (
          models.map((model) => (
            <Link
              to={`/models/${model.slug}`}
              key={model.id}
              className="model-card"
              style={{ textDecoration: "none" }}
            >
              <div key={model.id} className="model-card">
                {" "}
                {/* Make sure your model has a unique 'id' property */}
                <h3>
                  {model.name}, {model.age}
                </h3>
                {/* Corrected img tag below */}
                <img src={model.photo} alt={`Model ${model.name}`} />
                {/* Display other model details here */}
              </div>
            </Link>
          ))
        ) : (
          <p>No models found.</p>
        )}
      </div>
    </div>
  );
};

export default ModelList;
