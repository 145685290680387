import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const AuthContext = createContext();

const apiUrl = process.env.REACT_APP_API_URL;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to check the authentication status from the server
  const checkAuthStatus = async () => {
    try {
      const statusResponse = await axios.get(`${apiUrl}/auth/status`, {
        withCredentials: true,
      });

      if (statusResponse.data.isLoggedIn) {
        const userResponse = await axios.get(`${apiUrl}/users/me`, {
          withCredentials: true,
        });
        setUser(userResponse.data); // Set the user data
      } else {
        setUser(null); // Clear the user data if not logged in
      }

      setIsLoggedIn(statusResponse.data.isLoggedIn);
    } catch (error) {
      setIsLoggedIn(false);
      setUser(null); // Clear user data on error
    }
  };

  useEffect(() => {
    checkAuthStatus().finally(() => setLoading(false));
  }, []);

  useEffect(() => {}, [user]);

  const handleLogout = async () => {
    try {
      await axios.get(`${apiUrl}/auth/logout`, {
        withCredentials: true,
      });
      setIsLoggedIn(false);
      setUser(null); // Clear user data on logout
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        handleLogout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
