import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy for Studio Hub by ML Studios</h1>
            
            <section>
                <h2>Introduction</h2>
                <p>Welcome to Studio Hub by ML Studios, a vibrant community platform dedicated to models and photography enthusiasts. This Privacy Policy is designed to inform our users about how we collect, use, and protect the personal data and content shared on our platform.</p>
            </section>

            <section>
                <h2>Information Collection</h2>
                <p><strong>User-Submitted Content:</strong> Users are encouraged to submit pictures of models, which are subject to moderation to ensure they meet our community standards. All submissions are reviewed by our moderators prior to publication.</p>
                <p><strong>Personal Information:</strong> We collect personal information such as email addresses for account creation, communication, and enhancing user experience on our platform.</p>
                <p><strong>Profile Information:</strong> For those who create profiles, certain information will be publicly displayed and can be managed through user account settings.</p>
            </section>

            <section>
                <h2>Moderation Process</h2>
                <p>Our team of moderators rigorously reviews all user-submitted content to ensure it aligns with our community standards and guidelines. This process is crucial in maintaining the integrity and quality of content on our platform.</p>
            </section>

            <section>
                <h2>Usage of Submitted Content</h2>
                <p>Submitted and approved pictures may be showcased on our platform. Users retain rights to their content and can request removal at any time.</p>
            </section>

            <section>
                <h2>Data Sharing and Third-Party Involvement</h2>
                <p>We do not share any personal data or user-submitted content with third parties. Our platform is committed to maintaining the privacy and integrity of our user's data.</p>
            </section>

            <section>
                <h2>User Rights and Consent</h2>
                <p>Users have full control over their submitted content. Any approved content can be deleted by the user at their discretion.</p>
                <p>Users can access, update, or delete their personal information through their account settings.</p>
            </section>

            <section>
                <h2>Data Security</h2>
                <p>We are committed to protecting the data on our platform. Robust security measures are in place to prevent unauthorized access and to ensure the safety of our users’ information.</p>
            </section>

            <section>
                <h2>Compliance with Laws</h2>
                <p>Studio Hub by ML Studios adheres to all applicable privacy laws and regulations, ensuring the protection and proper handling of user data.</p>
            </section>

            <section>
                <h2>Policy Updates</h2>
                <p>Any changes to this Privacy Policy will be communicated through our website and will be effective upon posting.</p>
            </section>

            <section>
                <h2>Contact Information</h2>
                <p>For any questions or concerns regarding this Privacy Policy or data privacy practices, please contact us through our website.</p>
            </section>

            <section>
                <h2>Effective Date</h2>
                <p>This Privacy Policy is effective as of 22.11.2023.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
