import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/AuthContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import ModelList from "./components/ModelList";
import ModelProfileComponent from "./components/ModelProfileComponent";
import FullScreenImageComponent from "./components/FullScreenImageComponent";
import FullContainer from "./components/FullContainer";
import GalleryComponent from "./components/GalleryComponent";
import ModelsByTagComponent from "./components/ModelsByTagComponent";
import Login from "./components/Login";
import Register from "./components/Register";
import GoogleLoginHandler from "./components/GoogleLoginHandler";
import OAuthCallback from "./components/OAuthCallback";
import PrivacyPolicy from "./components/help/PrivacyPolicy";
import AccountDeletionPolicy from "./components/help/AccountDeletionPolicy";
import UserProfile from "./components/users/UserProfile";
import EditUserProfile from "./components/users/EditUserProfile";
import UsersList from "./components/users/UsersList";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Helmet>
            <meta
              name="description"
              content="Studio Hub by ML Studios - Home of Beautiful Models and Pornstars"
            />
          </Helmet>
          <Header />
          <FullContainer>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/models" element={<ModelList />} />
              <Route path="/models/:slug" element={<ModelProfileComponent />} />
              <Route
                path="/models/tags/:tagSlug"
                element={<ModelsByTagComponent />}
              />
              <Route
                path="/models/:slug/image/:imageId/:imageUrl"
                element={<FullScreenImageComponent />}
              />
              <Route path="/gallery" element={<GalleryComponent />} />
              <Route
                path="/users"
                element={
                  <ProtectedRoute allowedAccountTypes={["admin", "moderator"]}>
                    <UsersList />
                  </ProtectedRoute>
                }
              ></Route>
              <Route path="/users/:slug" element={<UserProfile />} />
              <Route path="/users/:slug/edit" element={<EditUserProfile />} />
              <Route path="/google-login" element={<GoogleLoginHandler />} />

              <Route path="/oauth" element={<OAuthCallback />} />
              <Route path="/help/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/help/account-deletion"
                element={<AccountDeletionPolicy />}
              />
            </Routes>
          </FullContainer>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
